/*
@contract drawer-menu {
  trigger {
    color: Color
    font-size: FontSize
    padding: Padding
  }
  link {
    weight: FontWeight
    color: Color
    hover-color: Color
    background-color: BackgroundColor
    background-hover-color: BackgroundColor
  }
  divider-margin-top: MarginTop
  divider-border: Border
  non-link-background-color: BackgroundColor
  non-link-color: Color
}
*/

.dialog {
  --modal-max-height: 100%;
  --modal-height: 100%;
  --modal-background-color: var(--color-white);
  --modal-margin: 0;
  --modal-panel-padding: var(--space-1x);
  --modal-panel-overflow: hidden;
  padding: 0;
  z-index: 9999;

  @media (--screen-md) {
    max-width: 300px;
  }
}

.hamburgerButton {
  height: var(--page-header-back-height, var(--page-header-height));
  font-size: var(--drawer-menu-trigger-font-size);
  width: var(--drawer-menu-trigger-back-width, auto);
  color: var(--drawer-menu-trigger-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--drawer-menu-trigger-padding);
  background-color: var(--drawer-menu-trigger-background-color, transparent);
  border-radius: var(--page-header-back-border-radius, 0);
}

.menu {
  --anchor-text-color-default: var(--color-white);
  --drawer-menu-header-border: 0;
  --drawer-menu-header-height: var(--space-12x);
  --drawer-menu-header-margin: 0 var(--space-2x);
  --drawer-menu-item-padding: var(--space-3x) 0;
  --drawer-menu-items-padding: var(--space-4x) var(--space-5x);
  --drawer-menu-panel-width: auto;
  --drawer-menu-link-size: 15px;
  --drawer-menu-link-weight: var(--font-weight-medium);
  font-size: var(--font-size-md);
}

.header {
  --anchor-gap: var(--space-2x);
  --anchor-placement: center;
  --anchor-text-color-active: var(--color-white);
  --anchor-text-color-default: var(--color-white);
  --anchor-text-color-hover: var(--color-white);
  --button-padding: 0;
  --button-width: 100%;
  --icon-button-align-items: center;
  --icon-button-display: flex;
  --icon-button-background-color: var(--color-black);
  --icon-button-background-color-hover: var(--color-gray-500);
  --icon-button-background-color-active: var(--color-gray-400);
  --icon-button-border-radius: var(--border-radius-8x);
  --icon-button-icon-display: flex;
  --icon-button-height: 32px;
  --icon-button-justify-content: center;
  --icon-button-width: 40px;
  --modal-close-button-position: initial;
  border-bottom: var(
    --drawer-menu-header-border,
    1px solid var(--color-gray-200)
  );
  margin: 0 var(--space-3x);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: var(--space-3x) 0;
  color: var(--drawer-menu-link-color);
}

.body {
  overflow-y: auto;
  height: calc(100vh - var(--space-16x));
  padding-bottom: var(--space-24x);

  @media (--screen-lg) {
    padding-bottom: 0;
  }
}

.list {
  list-style: none;
  padding: var(--space-3x);
}

.item {
  font-size: var(--font-size-md);
  font-weight: var(--drawer-menu-link-weight, var(--font-weight-bold));
  border-radius: var(--border-radius-2x);
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: var(--space-2x);
  color: var(--drawer-menu-link-color);
  text-decoration: none;
  border: none;
  width: 100%;
  background: var(--drawer-menu-link-background-color);
  padding: var(--space-3x) var(--space-2x);
}

.item:hover,
.item:focus {
  background: var(--drawer-menu-link-background-hover-color);
  color: var(--drawer-menu-link-hover-color);
}

.count {
  width: 20px;
  height: 20px;
  background: var(--color-green-600);
  color: var(--color-white);
  font-weight: bold;
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius-full);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.divider {
  border-top: var(--drawer-menu-divider-border);
  margin-top: var(--drawer-menu-divider-margin-top);
  padding-top: var(--space-3x);
}

.divider:first-child {
  border: 0;
  margin-top: 0;
  padding-top: 0;
}

.profileName {
  color: var(--color-gray-500);
  font-weight: var(--font-weight-semibold);
}

.noLinkCursor {
  cursor: auto;
}

.noLinkCursor:hover {
  background-color: var(--drawer-menu-non-link-background-color);
}
